import { autoinject } from 'aurelia-framework';
import { PackagingProduct } from 'models/PackagingProduct';
import { AuthHttpClient } from 'services/auth-http-client';
import { BaseApiService } from 'services/base-api-service';

@autoinject
export class PackagingProductService extends BaseApiService<PackagingProduct> {
  constructor(httpClient: AuthHttpClient) {
    super(httpClient, PackagingProduct);
  }
}
