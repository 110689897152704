import { autoinject } from 'aurelia-framework';
import { ProductMaterialCategory } from 'models';
import { AuthHttpClient } from 'services/auth-http-client';
import { BaseApiService } from 'services/base-api-service';

@autoinject
export class ProductMaterialCategoryService extends BaseApiService<ProductMaterialCategory> {
  constructor(httpClient: AuthHttpClient) {
    super(httpClient, ProductMaterialCategory);
  }
}
