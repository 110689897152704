import { autoinject } from 'aurelia-framework';
import { Models } from 'models/core';
import { AuthHttpClient } from 'services/auth-http-client';
import { BaseApiService } from 'services/base-api-service';

@autoinject
export class PlacementService extends BaseApiService<Models.Placement> {
  constructor(httpClient: AuthHttpClient) {
    super(httpClient, Models.Placement);
  }

  /*
    Placements:
    ---------------
    Sides = 1,
    Bottom = 2,
    AllNet = 4,
    ExtraBottom = 7,
    CombiBottomSmallFish = 8,
    CombiTopLargeFish = 9
    
    Shapes:
    ---------------
    Rectangular = 9,        
    Spaghetti = 7,
    Conednet = 3,
    Cylinder = 8,
    Combi = 10
    */
  getValidCombinations(isCombiNet = false): { [shapeId: number]: Array<number> } {
    const validCombinations: { [shapeId: number]: Array<number> } = {
      9: [1, 2],
      8: isCombiNet ? [8, 9] : [1, 2],
      3: [1, 2, 7],
      7: [1, 2, 7],
      10: [8, 9],
    };

    return validCombinations;
  }
}
