import { autoinject } from 'aurelia-framework';
import { Models } from 'models/core';
import { AuthHttpClient } from 'services/auth-http-client';
import { BaseApiService } from 'services/base-api-service';

@autoinject
export class ProductService extends BaseApiService<Models.Product> {
  constructor(httpClient: AuthHttpClient) {
    super(httpClient, Models.Product);
  }

  public getAllCached(): Promise<Array<Models.Product>> {
    // if the cache is already filled, return that - if not, fill the cache and return it
    if (this.cachedObjects) {
      return Promise.resolve(this.cachedObjects);
    }

    return this.getAll('/getallwithdeleted').then((res) => {
      res.forEach((x) => (x['ArticleNoAndName'] = x.ArticleNo + ' - ' + x.Name));

      res = res.sort(function (a, b) {
        return a.ArticleNo > b.ArticleNo ? 1 : b.ArticleNo > a.ArticleNo ? -1 : 0;
      });

      this.cachedObjects = res;

      return res;
    });
  }

  public async search(searchString: string) {
    return await this.httpClient.exec<any>(`${Models.Product.ApiUrl}/search?searchString=${searchString}`, {
      method: 'GET',
    });
  }

  public getList(filters: any): Promise<any> {
    let queryString: string = '';

    queryString += '?$count=true&$top=' + filters.top;

    if (filters.skip) {
      queryString += '&$skip=' + filters.skip;
    }
    if (filters.searchText) {
      // tslint:disable-next-line:quotemark
      queryString +=
        "&$filter=(contains(Name,'" + filters.searchText + "') or contains(ArticleNo,'" + filters.searchText + "'))";

      if (filters.source) {
        // tslint:disable-next-line:quotemark
        queryString += ' and ProductSource eq ' + filters.source;
      }
    }

    if (filters.orderBy) {
      queryString += '&$orderby=' + filters.orderBy;
    }
    /*     if (filters.orderByDirection) {
          queryString += '&$orderbyDirection=' + filters.orderByDirection;
        } */

    return this.getResponse(queryString);
  }
}
