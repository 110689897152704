import { autoinject } from 'aurelia-framework';
import { ProductMaterialType } from 'models';
import { AuthHttpClient } from 'services/auth-http-client';
import { BaseApiService } from 'services/base-api-service';

@autoinject
export class ProductMaterialTypeService extends BaseApiService<ProductMaterialType> {
  constructor(httpClient: AuthHttpClient) {
    super(httpClient, ProductMaterialType);
  }
}
